@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Rubik-Bold";
  src: url("../src/assets/fonts/Rubik-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Rubik-Regular";
  src: url("../src/assets/fonts/Rubik-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Rubik-SemiBold";
  src: url("../src/assets/fonts/Rubik-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Rubik-Medium";
  src: url("../src/assets/fonts/Rubik-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../src/assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../src/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}

html{
  @apply p-0 m-0 h-full overflow-hidden;
}

body{
  font-family: Rubik-Regular, sans-serif;
  height: -webkit-fill-available;
  height: -moz-available;
  background-image: linear-gradient( rgba(10, 23, 30, 0.7) 100%, rgba(10, 23, 30, 0.7) 100%);
  @apply p-0 m-0 box-border w-full relative h-full overflow-hidden sm:overflow-auto;
}

pre{
  @apply whitespace-normal w-full;
}

.tableItem:hover #watch{
  @apply bg-white text-black;
}

.tableItem:hover #watch>svg{
  @apply stroke-black;
}

.tableItem:hover #bet{
  @apply bg-green;
}

#root{
  @apply p-0 m-0 h-full;
}

.thinScrollbar::-webkit-scrollbar{
  @apply sm:w-[5px] sm:h-[5px];
}

.thinScrollbar::-webkit-scrollbar-thumb{
  @apply sm:bg-blue sm:rounded-3xl;
}

a{
  color: inherit;
  text-decoration: none;
}

.topBlock:hover>div>div>.topTeamLogoBlock{
  @apply lg:hidden;
}

.topBlock:hover>div>div>.topTeamNameBlock{
  @apply lg:block;
}

@keyframes spin{
  to { transform: rotate(-360deg); }
}

.loader{
  animation: spin 1s infinite linear;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur{
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
}

@keyframes trick{
  100% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-200%);
  }
}

.posSticky{
  @apply sticky lg:static;
}

.description>ul, .description>ul{
  @apply list-disc pl-[30px];
}

.description>ol>li, .description>ol>li{
  @apply float-left px-[5px] w-full text-justify;
}

.description>*, .description{
  @apply m-0 mt-3 w-full text-blue-300 text-base font-rubikRegular text-justify;
}

.description>*>strong{
  @apply font-rubikBold;
}

.description>*:is(h1, h2, h3, h4, h5, h6){
  @apply text-center font-rubikBold;
}

.description>h1{
  @apply text-[36px];
}

.description>h2{
  @apply text-[24px];
}

.description>h3{
  @apply text-[18px];
}

@keyframes plug{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.plugGradient{
  animation: plug 2s linear infinite;
}

.tableItemPlug{
  animation: plug 2s linear infinite;
  @apply bg-gradient-to-r from-darkBlueOpacity3 to-darkBlueOpacity bg-[length:200%_200%];
}

input[type=checkbox]{
  @apply accent-violet;
}

.absoluteBlock{
  @apply absolute w-full h-full z-50;
}

.topMatchHover:hover>.opacityBlock{
  @apply bg-blackOpacity1;
}

.borderRadius{
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

input[type=checkbox] {
  @apply accent-violet;
}

@keyframes spin{
  to { transform: rotate(-360deg); }
}

.loader{
  animation: spin 1s infinite linear;
}

.hoverStrokeLink:hover > svg{
  @apply stroke-violet;
}

.hoverFillLink:hover > svg{
  @apply fill-violet;
}

.hoverPlace:hover > div > svg{
  @apply rotate-90;
}

.hoverPlace:hover > .hoverBlock{
  @apply lg:flex;
}

.bgImage{
  background-image: linear-gradient( rgba(10, 23, 30, 0.45) 100%, rgba(10, 23, 30, 0.5) 100%),url('../public/images/modal/background.jpg');
  background-size: contain;
  background-position: 50% 50%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

input:-moz-autofill {
  box-shadow: 0 0 0 1000px white inset;
}

.half-colored {
  background: linear-gradient(to right, rgb(77, 197, 145) 50%, rgb(188, 235, 214) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.half-colored1 {
  background: linear-gradient(to right, rgb(77, 197, 145) 50%, rgb(255, 255, 255) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hasShadow {
  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.05);
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
