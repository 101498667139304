[hidden] {
    display: none;
}

:root {
    --primary-color: #19aeb7;
    --secondary-color: #1498a0;
    --background-color: #0b1117;
    --danger: #ff4d41;
    --white: #fff;
    --gray-75: rgba(255, 255, 255, 0.75);
    --gray-60: rgba(255, 255, 255, 0.6);
    --gray-10: rgba(255, 255, 255, 0.1);
    --font-family: 'Poppins', sans-serif;
    --font-size-body-lg: 20px;
    --font-size-body-md: 16px;
    --font-size-body-sm: 14px;
    --button-font-size: 16px;
    --line-height-body-md: 1.5;
    --border-radius: 10px;
    --font-size-h1: 56px;
    --font-size-h2: 44px;
    --font-size-h3: 32px;
    --swiper-pagination-color: #1498a0;
    --swiper-pagination-bullet-inactive-color: var(--gray-60);
}

@media screen and (max-width: 992px) {
    :root {
        --font-size-h1: 56px;
        --font-size-h2: 32px;
        --font-size-h3: 28px;
    }
}

.bodyAmember {
    margin: 0;
    color: var(--white);
    background-color: var(--background-color);
    font-family: var(--font-family);
    font-size: var(--font-size-body-md);
}

/* 
.bodyAmember button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: var(--white);
    border: none;
    border-radius: var(--border-radius);
    background: var(--primary-color);
    font-size: var(--button-font-size);
} */

/* .bodyAmember ul {
    padding: 0;
    list-style: none;
} */

.bodyAmember h1 {
    font-family: var(--font-family);
    /* font-size: var(--font-size-h1); */
    font-weight: 600;
}

.bodyAmember h2 {
    font-family: var(--font-family);
    /* font-size: var(--font-size-h2); */
    font-weight: 600;
}

.bodyAmember h3 {
    font-family: var(--font-family);
    /* font-size: var(--font-size-h3); */
    font-weight: 600;
}

.bodyAmember button {
    font-family: var(--font-family);
    /* font-size: var(--font-size-body-md); */
    font-weight: 600;
}

.body-modal {
    min-height: fit-content;
    background-image: none;
    height: auto;
    display: block;
}

.header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    max-width: 854px;
    height: 66px;
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
}

@media screen and (max-width: 992px) {
    .header {
        max-width: -webkit-fill-available;
    }
}

.pricing-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    background: transparent !important;
}

@media screen and (max-width: 992px) {
    .pricing-page {
        padding: 16px;
    }
}

.pricing-page__title {
    margin: 10px 0 32px;
    font-size: var(--font-size-h2);
    font-weight: 700;
    line-height: 1.3;
}

@media screen and (max-width: 992px) {
    .pricing-page__title {
        margin: 10px 0 16px;
    }
}

.pricing {
    padding: 16px 30px 30px;
    width: 100%;
    max-width: -webkit-fill-available;
}

@media screen and (max-width: 992px) {
    .pricing {
        padding: 16px 0 36px;
        width: none;
        max-width: none;
    }
}

@media screen and (max-width: 992px) {
    .pricing.swiper {
        overflow: hidden;
        width: 100%;
        padding-bottom: 36px;
    }
}

.pricing .swiper-wrapper {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(288px, 436px));
    gap: 30px;
}

@media screen and (max-width: 992px) {
    .pricing .swiper-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
    }
}

.pricing .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: auto;
    max-height: auto;

}

.pricing .swiper-pagination {
    display: none;
}

@media screen and (max-width: 992px) {
    .pricing .swiper-pagination {
        display: block;
        margin-top: 48px;
    }
}

.pricing__card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 36px 36px 40px 36px;
    -webkit-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    transition: background-color 0.2s;
    text-align: left;
    color: var(--white);
    height: 100%; /* Fallback */
    border-radius: 24px;
    background-color: var(--background-color);
}

@media screen and (max-width: 992px) {
    .pricing__card {
        width: 400px;
        max-width: 100%;
        padding: 36px 24px 40px 24px;
    }
}

.pricing-modal .pricing__card {
    height: auto;
}

.pricing__card .pricing__dot {
    position: relative;
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-bottom: 12px;
    margin-left: auto;
    border: 1px solid var(--white);
    border-radius: 50%;
}

.pricing__card .pricing__dot::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    content: '';
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: transparent;
}

@media screen and (max-width: 992px) {
    .pricing__card .pricing__dot {
        display: none;
    }
}

.pricing__card .pricing__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    font-size: var(--font-size-h3);
}

@media screen and (max-width: 992px) {
    .pricing__card .pricing__title {
        font-weight: 500;
    }
}

.pricing__card .pricing__subtitle {
    margin: 0;
    color: var(--gray-60);
    font-size: var(--font-size-body-md);
    line-height: var(--line-height-body-md);
}

.pricing__card .pricing__price {
    margin: 24px 0;
    font-size: 40px;
    font-weight: 600;
}

.pricing__card .pricing__price-text {
    margin-left: -8px;
    font-size: 20px;
    font-weight: 600;
    line-height: var(--line-height-body-md);
}

.pricing__card .pricing__features {
    display: -ms-grid;
    display: grid;
    margin-top: 24px;
    margin-bottom: 0;
    -ms-grid-columns: 1fr 8px 1fr;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
}

@media screen and (min-width: 992px) {
    .pricing .swiper-slide {
        max-height: 554px;
    }

    .pricing__card .pricing__features {
        grid-template-columns: repeat(2, 1fr);
    }
}

.pricing__card .pricing__features-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}

.pricing__card .pricing__features-text {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}

.pricing__card .pricing__btn {
    display: none;
}

@media screen and (max-width: 992px) {
    .pricing__card .pricing__btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        margin-top: 40px;
        padding: 10px;
        cursor: pointer;
        -webkit-transition:
            background-color 0.2s,
            color 0.2s;
        -o-transition:
            background-color 0.2s,
            color 0.2s;
        transition:
            background-color 0.2s,
            color 0.2s;
        color: var(--white);
        border: none;
        border-radius: 8px;
        background-color: var(--secondary-color);
        font-size: 16px;
        font-weight: 700;
    }

    .pricing__card .pricing__btn:hover {
        color: var(--primary-color);
        background-color: var(--white);
    }
}

.pricing__card .pricing__note {
    margin: 0;
    color: var(--gray-60);
    font-size: 14px;
    line-height: 1.5;
}

@media screen and (max-width: 992px) {
    .pricing__card .pricing__note {
        display: none;
    }
}

.pricing__card--selected {
    background-color: rgba(25, 174, 183, 0.5);
}

.pricing__card--selected .pricing__dot::after {
    width: 24px;
    height: 24px;
    background-color: var(--white);
}

.pricing__card--selected .custom-dropdown__button {
    border-color: var(--white);
}

.payment-system {
    width: 352px;
    max-width: 100%;
    margin: 16px auto 0;
    text-align: left;
    color: var(--white);
}

@media screen and (max-width: 992px) {
    .payment-system {
        display: none;
    }
}

.payment-system__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 8px;
}

.payment-system__button {
    width: 100%;
    height: 48px;
    margin-top: 8px;
    padding: 12px 24px;
    cursor: pointer;
    -webkit-transition:
        background-color 0.2s,
        color 0.2s;
    -o-transition:
        background-color 0.2s,
        color 0.2s;
    transition:
        background-color 0.2s,
        color 0.2s;
    color: #0c1921;
    border: none;
    border-radius: 8px;
    background-color: var(--white);
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.payment-system__button:hover {
    color: var(--primary-color);
    background-color: var(--white);
}

.radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    -webkit-transition:
        background-color 0.3s,
        font-weight 0.2s;
    -o-transition:
        background-color 0.3s,
        font-weight 0.2s;
    transition:
        background-color 0.3s,
        font-weight 0.2s;
    border-radius: 8px;
    background-color: #1d3340;
    font-size: 16px;
}

.radio:hover {
    background-color: rgba(29, 51, 64, 0.5);
}

.radio--selected {
    background-color: #13646c;
    font-weight: 600;
}

.radio__input {
    display: none;
}

.radio__label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.radio__custom {
    position: relative;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 12px;
    -webkit-transition:
        background-color 0.3s,
        border-color 0.3s;
    -o-transition:
        background-color 0.3s,
        border-color 0.3s;
    transition:
        background-color 0.3s,
        border-color 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.5019607843);
    border-radius: 50%;
}

.radio__custom::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    content: '';
    -webkit-transition:
        width 0.3s,
        height 0.3s,
        background-color 0.3s;
    -o-transition:
        width 0.3s,
        height 0.3s,
        background-color 0.3s;
    transition:
        width 0.3s,
        height 0.3s,
        background-color 0.3s;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: transparent;
}

.radio--selected .radio__custom {
    border-color: var(--white);
}

.radio__input:checked + .radio__custom {
    border-color: var(--white);
}

.radio__input:checked + .radio__custom::after {
    width: 16px;
    height: 16px;
    background-color: var(--white);
}

.custom-dropdown {
    position: relative;
    width: 100%;
}

.custom-dropdown__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    color: var(--white);
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    background-color: transparent;
    gap: 8px;
    isolation: isolate;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}

.custom-dropdown__button:hover {
    background-color: rgba(25, 174, 183, 0.3);
}

.custom-dropdown__icon {
    width: 10px;
    height: 10px;
    /* margin-bottom: 8px; */
    margin-left: 8px;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition:
        transform 0.3s,
        -webkit-transform 0.3s;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform-origin: center;
}

.custom-dropdown__menu {
    position: absolute;
    z-index: 10;
    top: calc(100% + 4px);
    left: 0;
    display: none;
    overflow: hidden;
    width: 100%;
    margin: 0;
    list-style: none;
    border-radius: 8px;
    background: rgba(25, 174, 183, 0.25);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
}

.custom-dropdown__menu.open {
    display: block;
}

.custom-dropdown__item {
    padding: 10px 16px 10px 12px;
    cursor: pointer;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
    color: var(--white);
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.custom-dropdown__item:hover {
    background-color: rgba(10, 9, 15, 0.5);
}

.custom-dropdown__item.selected {
    background-color: rgba(10, 9, 15, 0.5);
}

.custom-dropdown__button.open .custom-dropdown__icon {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 1248px;
    max-width: 100%;
    margin: 78px auto 0;
    padding: 32px 48px;
    border-radius: 16px 16px 0px 0px;
    background: var(--gray-10);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
}

@media screen and (max-width: 992px) {
    .footer {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-top: 24px;
        padding: 32px 16px;
        border-radius: 0;
        gap: 40px;
    }
}

.footer__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 32px;
}

@media screen and (max-width: 992px) {
    .footer__info {
        gap: 20px;
    }
}

.footer__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 32px;
}

@media screen and (max-width: 992px) {
    .footer__links {
        gap: 16px;
    }
}

.footer__links .footer__link {
    text-align: center;
    text-decoration: none;
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.footer__links .footer__link:hover {
    text-decoration: underline;
}

.footer__copyright {
    margin: 0;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.footer__trust {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 24px;
}

@media screen and (max-width: 992px) {
    .footer__trust {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        gap: 16px;
    }
}

.footer__trust-logo {
    -o-object-fit: contain;
    object-fit: contain;
}

.footer__trust-text {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(12, 25, 33, 0.6);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

.modal__content {
    position: relative;
    width: 100%;
    max-width: 370px;
    margin: 16px;
    padding: 20px 16px;
    color: #0a090f;
    border-radius: 16px;
    background: #ffffff;
}

.modal__title {
    margin: 8px 0 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}

.modal__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 8px;
    gap: 12px;
}

.modal__form .radio {
    padding: 12px;
    border: 1px solid rgba(10, 9, 15, 0.2);
    background-color: transparent;
    gap: 0;
}

.modal__form .radio__custom {
    margin-right: 4px;
    border-color: #19aeb7;
}

.modal__form .radio__label-logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 14px;
}

.payment-system__form .radio__label-logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 4px;
}

.payment-logos__img {
    height: 16px;
}

@supports (selector(: has(*))) {
    .modal__form .radio:has(.radio__input:checked) {
        padding: 11px;
        border: 2px solid #19aeb7;
    }
}

.modal__form .radio__input:checked + .radio__custom {
    border-color: #19aeb7;
}

.modal__form .radio__input:checked + .radio__custom::after {
    background-color: #19aeb7;
}

.modal__button {
    width: 100%;
    height: 48px;
    margin-top: 22px;
    padding: 10px;
    cursor: pointer;
    -webkit-transition:
        background-color 0.2s,
        color 0.2s;
    -o-transition:
        background-color 0.2s,
        color 0.2s;
    transition:
        background-color 0.2s,
        color 0.2s;
    color: var(--white);
    border: none;
    border-radius: 8px;
    background-color: #19aeb7;
    font-size: 16px;
    font-weight: 700;
}

.modal__button:hover {
    background-color: #128a94;
}

.modal__close {
    position: absolute;
    top: 20px;
    right: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    cursor: pointer;
    -webkit-transition: 0.2s background-color;
    -o-transition: 0.2s background-color;
    transition: 0.2s background-color;
    border: none;
    border-radius: 16px;
    background-color: rgba(25, 174, 183, 0.1019607843);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
}

.modal__close:hover {
    background-color: rgba(25, 174, 183, 0.3);
}

.modal__close-img {
    -o-object-fit: contain;
    object-fit: contain;
}

.modal.open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/* ÐŸÑ€Ð¸ Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ð¾ÑÑ‚Ð¸, Ð¼Ð¾Ð¶Ð½Ð¾ ÑƒÐ¿Ñ€Ð°Ð²Ð»ÑÑ‚ÑŒ Ð²Ð¸Ð´Ð¸Ð¼Ð¾ÑÑ‚ÑŒÑŽ Ð¼Ð¾Ð´Ð°Ð»ÐºÐ¸ Ñ‡ÐµÑ€ÐµÐ· .modal.show */
.modal {
    display: none;
}

.modal.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/* Ð Ñ‚Ð°ÐºÐ¶Ðµ ÑÑ‚Ð¸Ð»Ð¸ Ð´Ð»Ñ .custom-dropdown__menu.open, .custom-dropdown__button.open */
.custom-dropdown__button.open + .custom-dropdown__menu {
    display: block;
    /* Ð¸Ð»Ð¸ Ð²Ð°Ñˆ ÑÐ¿Ð¾ÑÐ¾Ð± Ð°Ð½Ð¸Ð¼Ð°Ñ†Ð¸Ð¸ */
}

.sign-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 48px 16px;
    text-align: center;
}

.sign-page__title {
    margin-bottom: 16px;
    color: var(--white);
    font-size: var(--font-size-h2);
    font-weight: 700;
    line-height: 1.3;
}

.sign-page__subtitle {
    margin-bottom: 32px;
    color: var(--gray-75);
    font-size: var(--font-size-body-lg);
    font-weight: 400;
}

.sign-page__subtitle a {
    text-decoration: underline;
    color: var(--white);
    font-weight: 600;
}

.sign-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 400px;
    max-width: 100%;
    margin-top: 32px;
    padding: 32px;
    border-radius: 16px;
    background: rgba(20, 152, 160, 0.2);
    gap: 8px;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
}

.form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 16px;
}

.form__field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 42px;
    padding: 0 12px;
    color: var(--white);
    border-radius: 8px;
    background-color: #0b1820;
    font-size: 16px;
}

.form__field .form__toggle {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    border: none;
    background: none;
}

.form__field .form__toggle .form__toggle-icon {
    display: block;
}

.form__field .form__toggle .form__toggle-icon.show {
    display: block;
}

.form__field .form__toggle .form__toggle-icon.hide {
    display: none;
}

.form__field .form__toggle.visible .form__toggle-icon.show {
    display: none;
}

.form__field .form__toggle.visible .form__toggle-icon.hide {
    display: block;
}

.form__field .form__input {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 8px 8px 8px 2px;
    color: var(--white);
    border: none;
    background: none;
    font-size: 16px;
}

.form__field .form__input::-webkit-input-placeholder {
    color: var(--gray-75);
}

.form__field .form__input::-moz-placeholder {
    color: var(--gray-75);
}

.form__field .form__input:-ms-input-placeholder {
    color: var(--gray-75);
}

.form__field .form__input::-ms-input-placeholder {
    color: var(--gray-75);
}

.form__field .form__input::placeholder {
    color: var(--gray-75);
}

.form__field .form__input:focus {
    border-color: var(--primary-color);
    outline: none;
}

.form__field .form__icon {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-column-align: center;
    justify-self: center;
}

.form__field .form__asterisk {
    margin-bottom: 12px;
    color: var(--danger);
}

.form__note {
    margin: 36px 0 0;
    text-align: left;
    color: var(--white);
    font-size: 14px;
    line-height: 1.5;
}

.form__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 24px;
    gap: 8px;
}

.form__actions .button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 46px;
    padding: 10px;
    cursor: pointer;
    -webkit-transition:
        background-color 0.3s,
        color 0.3s;
    -o-transition:
        background-color 0.3s,
        color 0.3s;
    transition:
        background-color 0.3s,
        color 0.3s;
    text-align: center;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
}

.form__actions .button--secondary {
    color: var(--white);
    background-color: var(--secondary-color);
}

.form__actions .button--secondary:hover {
    background-color: var(--primary-color);
}

.form__actions .button--primary {
    color: var(--secondary-color);
    border: none;
    background-color: var(--white);
}

.form__actions .button--primary:hover {
    color: var(--primary-color);
}

input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: var(--gray-75);
}

.window {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(10, 9, 15, 0.01);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

.window .window-page {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 811px;
    max-width: 99vw;
    height: 900px;
    max-height: 95vh;
    margin: auto;
}

.pricing-modal {
    display: -ms-grid;
    display: grid;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
    margin: auto;
    border-radius: 16px;
    -ms-grid-columns: 43% minmax(440px, 57%);
    grid-template-columns: 43% minmax(440px, 57%);
    height: 100%;
}

@media screen and (max-width: 992px) {
    .pricing-modal {
        width: 100%;
        background-color: var(--white);
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        gap: 21px;
    }
}

.pricing-modal .modal__form {
    color: #0a090f;
}

.pricing-modal .pricing__card {
    display: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto 22px;
    opacity: 0.5;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translateX(-20%);
    -ms-transform: translateX(-20%);
    transform: translateX(-20%);
    -webkit-transition:
        opacity 0s ease,
        -webkit-transform 0.5s ease;
    transition:
        opacity 0s ease,
        -webkit-transform 0.5s ease;
    -o-transition:
        opacity 0s ease,
        transform 0.5s ease;
    transition:
        opacity 0s ease,
        transform 0.5s ease;
    transition:
        opacity 0s ease,
        transform 0.5s ease,
        -webkit-transform 0.5s ease;
    background: #1b565d;
}

.pricing-modal .pricing__card--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-animation: slideIn 0s forwards;
    animation: slideIn 0s forwards;
}

.pricing-modal .pricing__dot {
    display: none;
}

.pricing-modal__options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 14px;
    color: var(--white);
    background-color: rgba(25, 174, 183, 0.5);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    padding-top: 16px;
    height: auto;
}

@media screen and (max-width: 992px) {
    .pricing-modal__options {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 0;
        color: #0a090f;
        border: 1px solid rgba(10, 9, 15, 0.2);
        background-color: transparent;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
        height: 100%;
        margin: 10px 10px;
        max-height: none;
        border-radius: 12px;
    }
}

.pricing-modal .pricing-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 6px 12px 16px 12px;
    cursor: pointer;
    -webkit-transition:
        background-color 0.3s,
        border-color 0.3s;
    -o-transition:
        background-color 0.3s,
        border-color 0.3s;
    transition:
        background-color 0.3s,
        border-color 0.3s;
    color: var(--white);
    border-bottom: 1px solid #1b565d;
    border-radius: 12px;
}

.pricing-modal .pricing-option:hover {
    background-color: rgb(20 152 160 / 90%);
}

@media screen and (max-width: 992px) {
    .pricing-modal .pricing-option {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 6px 12px;
        text-align: center;
        color: #0a090f;
        border: none;
        width: 100%;
    }
}

.pricing-modal .pricing-option:last-of-type {
    border-bottom-color: transparent;
}

.pricing-modal .pricing-option__title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
}

@media screen and (max-width: 992px) {
    .pricing-modal .pricing-option__title {
        font-weight: 400;
    }
}

.pricing-modal .pricing-option__description {
    margin: 0;
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

@media screen and (max-width: 992px) {
    .pricing-modal .pricing-option__description {
        display: none;
    }
}

.pricing-modal .pricing-option__note {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: auto 0 0;
    padding: 16px 12px;
    color: var(--white);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    font-size: 16px;
    gap: 10px;
}

@media screen and (max-width: 992px) {
    .pricing-modal .pricing-option__note {
        display: none;
    }
}

.pricing-modal .pricing-option__note-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 24px;
    min-width: 24px;
    height: 24px;
    color: var(--white);
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
}

.pricing-modal .pricing-option--selected {
    background-color: #1b565d;
}

@media screen and (max-width: 992px) {
    .pricing-modal .pricing-option--selected {
        color: var(--white);
        background-color: var(--secondary-color);
    }

    .pricing-modal .pricing-option--selected .pricing-option__title {
        font-weight: 600;
    }
}

.pricing-modal__details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 24px 48px;
    text-align: center;
    color: #0a090f;
    background: var(--white);
    overflow-x: hidden;
}

@media screen and (max-width: 992px) {
    .pricing-modal__details {
        padding: 16px;
        padding-top: 0;
    }
}

.pricing-modal__header {
    margin-bottom: 20px;
    text-align: center;
}

@media screen and (max-width: 992px) {
    .pricing-modal__header {
        display: none;
    }
}

.pricing-modal__heading {
    margin: 6px 0 8px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}

.pricing-modal__subheading {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
}

.pricing-card {
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 8px;
    background: #f5f5f5;
}

.pricing-card__title {
    font-size: 20px;
    font-weight: 600;
}

.pricing-card__price {
    margin: 8px 0;
    color: #118ab2;
    font-size: 18px;
}

.pricing-card__price-text {
    color: #666;
    font-size: 12px;
}

.pricing-card__features {
    display: -ms-grid;
    display: grid;
    margin-top: 16px;
    padding: 0;
    list-style: none;
    gap: 8px;
}

.pricing-card__features__feature {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 8px;
}

.pricing-card__features__feature__icon {
    color: #118ab2;
    font-size: 18px;
}

.dropdown__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: var(--white);
}

.dropdown__button__icon {
    width: 16px;
    height: 16px;
}

.dropdown__menu {
    display: none;
    margin: 8px 0;
    padding: 8px;
    list-style: none;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: var(--white);
}

.dropdown__menu__item {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown__menu__item:hover {
    background: #f5f5f5;
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: translateX(20%);
        transform: translateX(20%);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideIn {
    0% {
        -webkit-transform: translateX(20%);
        transform: translateX(20%);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.logos-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 48px;
}

.logos-wrapper.logos-wrapper-modal {
    margin-top: 8px;
}

.logos {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-size: var(--font-size-body-md);
    font-weight: 500;
    gap: 12px;
}

.logos .logos__img {
    width: auto;
    height: 24px;
}

.logos-wrapper .payment-system__button {
    width: auto;
    margin-top: 24px;
}
